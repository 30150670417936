












import SUserSearch from '../../participants/components/SUserSearch.vue';
import {SPECIFIC_USER_PROFILE} from '@/router/route-names';
import {computed, defineComponent, ref, watch} from '@vue/composition-api';
import {RawUser} from '@/users/types/RawUser';
import {useRouter} from '@/router/composables';

export default defineComponent({
  name: 'SearchUser',
  components: {SUserSearch},
  setup() {
    const $router = useRouter();

    const user = ref<string | null>(null);
    const users = ref<RawUser[]>([]);
    const selectedUser = computed(
      () => users.value.filter((u) => u.email === user.value)[0] || null
    );

    watch(selectedUser, (newUser) => {
      if (newUser) {
        $router.push({
          name: SPECIFIC_USER_PROFILE,
          params: {
            userId: newUser.id.toString(),
          },
        });
      }
    });

    return {
      user,
      users,
      selectedUser,
    };
  },
});
