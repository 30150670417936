




















import {LoadingFlag} from '@/loading/types/LoadingFlags';
import {getUsers} from '@/users/api/users';
import {computed, defineComponent, PropType, ref, watch} from '@vue/composition-api';
import {inject} from '@/container';
import {LoadingFlagsService} from '@/loading/services/LoadingFlagsService';
import {RawUser} from '@/users/types/RawUser';
import {useDebounceFn} from '@vueuse/core';
import {IStembleAxiosPromise} from '@/common/api/types/IStembleAxiosPromise';

export default defineComponent({
  name: 'SUserSearch',
  props: {
    getUserFn: {
      type: Function as PropType<(query: string) => IStembleAxiosPromise>,
      default: () => (q: string) => getUsers({q}),
    },
    returnObject: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
    label: {
      type: String as PropType<string>,
      default: null,
    },
    prependIcon: {
      type: String as PropType<string>,
      default: 'mdi-email',
    },
    placeholder: {
      type: String as PropType<string>,
      default: null,
    },
  },
  setup(props, {emit}) {
    const $loadingFlags = inject(LoadingFlagsService);

    const userOptions = ref<RawUser[]>([]);
    const search = ref<string | null>(null);

    const isFetchingUsersToAdd = computed(() =>
      $loadingFlags.isLoading(LoadingFlag.RosterManagementLoadingUsers)
    );

    const computedUserOptions = computed(() =>
      userOptions.value.map((user) => ({
        ...user,
        text: `${user.firstName} ${user.lastName} (${user.email ? user.email + ', ' : ''}${
          user.studentId ? user.studentId : ''
        })`,
        value: user.email,
      }))
    );

    async function updateSearchUsers(query: string) {
      const res = await $loadingFlags.loadingHandler(
        LoadingFlag.RosterManagementLoadingUsers,
        props.getUserFn(query)
      );
      userOptions.value = res.data.data;
      emit('update:users', userOptions.value);
    }

    const debouncedUpdateSearchUsers = useDebounceFn(updateSearchUsers, 500);

    watch(search, (val: string | null) => {
      if (!val) {
        return;
      }
      debouncedUpdateSearchUsers(val);
    });

    return {
      search,
      computedUserOptions,
      isFetchingUsersToAdd,
    };
  },
});
